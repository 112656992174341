import md5 from 'js-md5';
import { Base64 } from 'js-base64';
import _ from 'lodash';
import jwt from 'jsonwebtoken';
import * as Sentry from '@sentry/browser';
import type { Request, Response, NextFunction } from 'express';
import type { User } from './user.ts';
import type { Database } from '../server/db.ts';

export const buildDict = <K extends string | number | symbol, V, Args extends unknown[]>(
    fn: GeneratorFunc<[K, V], Args>, ...args: Args
): Record<K, V> => {
    const dict: Record<K, V> = {} as Record<K, V>;
    for (const [key, value] of fn(...args)) {
        dict[key] = value;
    }
    return dict;
};

export const buildList = <V, Args extends unknown[]>(fn: GeneratorFunc<V, Args>, ...args: Args): V[] => {
    const list = [];
    for (const value of fn(...args)) {
        list.push(value);
    }
    return list;
};

export const deepGet = (obj: object, path: string): unknown => {
    let value: any = obj;
    for (const part of path.split('.')) {
        value = value[part];
        if (value === undefined || value === null) {
            break;
        }
    }

    return value;
};

export function* deepListKeys(obj: object): Generator<string> {
    for (const [key, value] of Object.entries(obj)) {
        if (value instanceof Object && !Array.isArray(value)) {
            for (const subkey of deepListKeys(value)) {
                yield `${key}.${subkey}`;
            }
        } else {
            yield key;
        }
    }
}

export const clearUrl = (url: string): string => {
    url = url.trim()
        .replace('http://www.', '')
        .replace('https://www.', '')
        .replace('http://', '')
        .replace('https://', '')
        .replace('mailto:', '');

    const qPos = url.indexOf('?');
    if (qPos > -1) {
        url = url.substr(0, qPos);
    }

    const hPos = url.indexOf('#');
    if (hPos > -1) {
        url = url.substr(0, hPos);
    }

    if (url.substring(url.length - 1) === '/') {
        url = url.substring(0, url.length - 1);
    }

    url = decodeURIComponent(url);

    return url;
};

export const buildImageUrl = (cloudfrontUrl: string, imageId: string, size: string): string => {
    return `${cloudfrontUrl}/images/${imageId}-${size}.png`;
};

export const makeId = (
    length: number,
    characters: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
): string => {
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
};

export const fallbackAvatar = (user: Pick<User, 'username'>, size: number = 240): string => {
    return `https://avi.avris.it/shape-${size}/${Base64.encode(user.username).replace(/\+/g, '-')
        .replace(/\//g, '_')}.png`;
};

export const gravatar = (user: Pick<User, 'username' | 'email'> & { emailHash?: string }, size: number = 240): string => {
    return `https://www.gravatar.com/avatar/${user.emailHash || md5(user.email)}?d=${encodeURIComponent(fallbackAvatar(user, size))}&s=${size}`;
};

export interface DictEntry<K extends string | number | symbol, V> {
    key: K;
    value: V;
}
export const dictToList = <K extends string | number | symbol, V>(dict: Record<K, V>): DictEntry<K, V>[] => {
    const list = [];
    for (const key in dict) {
        if (dict.hasOwnProperty(key)) {
            list.push({ key, value: dict[key] });
        }
    }
    return list;
};

export const listToDict = <K extends string | number | symbol, V>(list: DictEntry<K, V>[]): Record<K, V> => {
    if (Object.keys(list).length === 0) {
        return {} as Record<K, V>;
    }
    const dict: Record<K, V> = {} as Record<K, V>;
    for (const el of list) {
        dict[el.key] = el.value;
    }
    return dict;
};

export function curry<T, A, B, R>(func: (this: T, a: A, b: B) => R): (a: A) => (this: T, b: B) => R {
    return function curried(a: A) {
        return function (b: B) {
            return func.apply(this, [a, b]);
        };
    };
}

export const capitalise = function (word: string): string {
    return word.substring(0, 1).toUpperCase() + word.substring(1);
};

export const camelCase = function (words: string[]): string {
    const text = words.map(capitalise).join('');
    return text.substring(0, 1).toLowerCase() + text.substring(1);
};

/**
 * Gets the current timestamp in seconds.
 */
export const now = (): number => {
    return Math.floor(Date.now() / 1000);
};

export const isEmoji = (char: string): boolean => {
    return _.toArray(char).length === 1 && !!char.trim().match(/(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/);
};

export function zip<K extends keyof unknown, V>(list: [K, V][], reverse: false): Record<K, V>;
export function zip<K extends keyof unknown, V>(list: [V, K][], reverse: true): Record<K, V>;
export function zip<K extends keyof unknown, V>(list: [K, V][] | [V, K][], reverse: boolean): Record<K, V> {
    return buildDict(function* () {
        for (const [k, v] of list) {
            yield reverse ? [v, k] : [k, v];
        }
    } as () => Generator<[K, V]>);
}

// https://stackoverflow.com/a/6274381/3297012
export const shuffle = <T>(array: T[]): T[] => {
    const a = [...array];
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
};

export const randomItem = <T>(array: T[]): T => array[Math.floor(Math.random() * array.length)];

interface WeightedItem {
    chance?: number;
}

export const randomItemWeighted = <T extends WeightedItem>(array: T[]): T => {
    const totalChance = array.reduce((sum, obj) => sum + (obj.chance ?? 1), 0);
    let randomChance = Math.random() * totalChance;

    for (const el of array) {
        randomChance -= el.chance ?? 1;
        if (randomChance <= 0) {
            return el;
        }
    }

    return array[array.length - 1];
};

export const randomNumber = (min: number, max: number): number => Math.floor(Math.random() * (max - min + 1)) + min;


const RESTRICTED_AREAS = ['code', 'org', 'impersonate', 'community'];

export const isGrantedForUser = (user: Pick<User, 'roles'>, locale: string | null, area: string = ''): boolean => {
    if (area === '*') {
        return user.roles.split('|').includes('*');
    }

    for (const permission of user.roles.split('|')) {
        if (permission === '*' && !RESTRICTED_AREAS.includes(area)) {
            return true;
        }
        const [permissionLocale, permissionArea] = permission.split('-');
        if ((permissionLocale === '*' || permissionLocale === locale || locale === null) &&
            (permissionArea === '*' && !RESTRICTED_AREAS.includes(area) ||
                permissionArea === area || area === '' || area === 'panel' && permissionArea !== 'users')
        ) {
            return true;
        }
    }

    return false;
};

type ErrorAsyncFunction = (req: Request, res: Response, next: NextFunction) => Promise<unknown>;
export const handleErrorAsync = (func: ErrorAsyncFunction) => {
    return (req: Request, res: Response, next: NextFunction): void => {
        func(req, res, next).catch((error: unknown) => next(error));
    };
};

export const clearLinkedText = (text: string, quotes: boolean = true): string => {
    text = text
        .replace(/{[^}=]+=([^}=]+)}/g, '$1')
        .replace(/{([^}=]+)}/g, '$1');

    if (quotes) {
        text = text.replace(/[„”"']/g, '');
    }

    text = text.replace(/\s+/g, ' ');

    return text;
};

export const sortClearedLinkedText = <T extends Record<K, string>, K extends string>(items: T[], key: K): T[] => {
    items.sort((a, b) => clearLinkedText(a[key].toLowerCase()).localeCompare(clearLinkedText(b[key].toLowerCase())));
    return items;
};

export const clearKey = (key: string | null): string | null => {
    if (!key) {
        return null;
    }
    return key.replace(/'/g, '_').toLowerCase();
};

export const sleep = (milliseconds: number): Promise<void> => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export const splitSlashes = (path: string): string[] => {
    const chunks = [];
    let escape = false;
    let currentChunk = '';
    for (const character of path) {
        if (escape) {
            currentChunk += `\`${character}`;
            escape = false;
        } else {
            if (character === '`') {
                escape = true;
            } else if (character === '/') {
                chunks.push(currentChunk);
                currentChunk = '';
            } else {
                currentChunk += character;
            }
        }
    }
    chunks.push(currentChunk);
    return chunks;
};

const escapeChars = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
};

export const escapeHtml = (text: string): string => {
    for (const [character, replacement] of Object.entries(escapeChars)) {
        text = text.replaceAll(character, replacement);
    }
    return text;
};

export const escapeControlSymbols = (text: string | null): string | null => {
    if (text === null) {
        return null;
    }
    // a backtick is used because browsers replace backslashes
    // with forward slashes if they are not url-encoded
    return text.replaceAll(/[`&/|,:]/g, '`$&');
};

export const unescapeControlSymbols = (text: string | null): string | null => {
    if (text === null) {
        return null;
    }
    return text.replaceAll(/`(.)/g, '$1');
};

export const escapePronunciationString = (text: string): string => {
    return text.replaceAll('\\', '\\\\')
        .replaceAll('/', '\\/');
};

export const unescapePronunciationString = (pronunciationString: string): string => {
    return pronunciationString.replaceAll('\\/', '/')
        .replaceAll('\\\\', '\\');
};

export const convertPronunciationStringToSsml = (pronunciationString: string): string => {
    const escapedString = escapeHtml(pronunciationString);
    let ssml = '';
    let escape = false;
    let currentPhonemes = null;
    for (const character of escapedString) {
        if (escape) {
            if (currentPhonemes === null) {
                ssml += character;
            } else {
                currentPhonemes += character;
            }
            escape = false;
        } else {
            if (character === '\\') {
                escape = true;
            } else if (character == '/') {
                if (currentPhonemes === null) {
                    currentPhonemes = '';
                } else {
                    ssml += `<phoneme alphabet="ipa" ph="${currentPhonemes}"></phoneme>`;
                    currentPhonemes = null;
                }
            } else {
                if (currentPhonemes === null) {
                    ssml += character;
                } else {
                    currentPhonemes += character;
                }
            }
        }
    }
    if (currentPhonemes !== null) {
        ssml += `/${currentPhonemes}`;
    }
    return `<speak>${ssml}</speak>`;
};

export class ImmutableArray<T> extends Array<T> {
    override map<U>(callbackFn: (value: T, index: number, array: T[]) => U): ImmutableArray<U> {
        return super.map(callbackFn) as ImmutableArray<U>;
    }

    override filter(predicate: (value: T, index: number, array: T[]) => unknown): ImmutableArray<T> {
        return super.filter(predicate) as ImmutableArray<T>;
    }

    sorted(compareFn?: (a: T, b: T) => number): ImmutableArray<T> {
        return new ImmutableArray(...[...this].sort(compareFn));
    }

    randomElement(): T {
        return this[Math.floor(Math.random() * this.length)];
    }

    groupBy(m: (element: T) => string | number): ImmutableArray<[string | number, ImmutableArray<T>]> {
        const keys: Record<string | number, number> = {};
        const grouped: ImmutableArray<[string | number, ImmutableArray<T>]> = new ImmutableArray();
        for (const el of this) {
            const key = m(el);
            if (!keys.hasOwnProperty(key)) {
                keys[key] = grouped.length;
                grouped.push([key, new ImmutableArray()]);
            }
            grouped[keys[key]][1].push(el);
        }

        return grouped;
    }

    indexOrFallback(index: number, fallback: T): T {
        return this.length > index ? this[index] : fallback;
    }
}

export const groupBy = <V>(list: V[], fn: (element: V) => string): Record<string, V[]> => {
    const grouped: Record<string, V[]> = {};
    for (const el of list) {
        const key = fn(el);
        if (!Object.hasOwn(grouped, key)) {
            grouped[key] = [];
        }
        grouped[key].push(el);
    }

    return grouped;
};

export const obfuscateEmail = (email: string): string | null => {
    const [username, hostname] = email.toLowerCase().split('@');
    const tld = hostname.split('.').slice(-1)
        .pop();

    if (tld === 'oauth') {
        return null;
    }

    const usernamePublic = username.substring(0, username.length <= 5 ? 1 : 3);

    return `${usernamePublic}*****@*****.${tld}`;
};

// https://newbedev.com/dynamic-deep-setting-for-a-javascript-object
export const deepSet = (obj: object, path: string, value: unknown): void => {
    const a = path.split('.');
    let o: any = obj;
    while (a.length - 1) {
        const n = a.shift()!;
        if (!(n in o)) {
            o[n] = {};
        }
        o = o[n];
    }
    o[a[0]] = value;
};

type AdminUser = Pick<User, 'username' | 'email' | 'roles' | 'adminNotifications'>;

export const findAdmins = async (db: Database, locale: string, area: string): Promise<AdminUser[]> => {
    const admins = await db.all<AdminUser>('SELECT username, email, roles, adminNotifications FROM users WHERE roles != \'\'');
    return admins.filter((admin) => isGrantedForUser(admin, locale, area));
};

export const isValidLink = (url: string | URL): boolean => {
    try {
        url = new URL(url);
        return ['http:', 'https:', 'mailto:'].includes(url.protocol);
    } catch {
        return false;
    }
};

export const addSlash = (link: string): string => {
    return link + (['*', '\''].includes(link.substring(link.length - 1)) ? '/' : '');
};

export const parseUserJwt = (token: string, publicKey: string, allLocalesUrls: string[]): User | null => {
    try {
        const parsed = jwt.verify(token, publicKey, {
            algorithms: ['RS256'],
            audience: allLocalesUrls,
            issuer: allLocalesUrls,
        });
        if (typeof parsed === 'string') {
            return null;
        }
        return parsed as User;
    } catch (error) {
        Sentry.captureException(error);
        return null;
    }
};

export const filterObjectKeys = <T extends Record<string, any>, K extends keyof T>(obj: T, keysToKeep: K[]): Pick<T, K> => {
    return keysToKeep.reduce((filteredObj, key) => {
        if (key in obj) {
            filteredObj[key] = obj[key];
        }
        return filteredObj;
    }, {} as Pick<T, K>);
};
