import { default as abuseReportsdzqOCCQkMUMeta } from "/home/admin/www/pronomejo.net/release/20241027111005/pages/admin/abuseReports.vue?macro=true";
import { default as _91id_93Cbw8B0fyORMeta } from "/home/admin/www/pronomejo.net/release/20241027111005/pages/admin/audit-log/[username]/[id].vue?macro=true";
import { default as index97vAdVKz2yMeta } from "/home/admin/www/pronomejo.net/release/20241027111005/pages/admin/index.vue?macro=true";
import { default as moderationjiowV9jLE7Meta } from "/home/admin/www/pronomejo.net/release/20241027111005/pages/admin/moderation.vue?macro=true";
import { default as pendingBansI7GUyfD2TZMeta } from "/home/admin/www/pronomejo.net/release/20241027111005/pages/admin/pendingBans.vue?macro=true";
import { default as profilesbFIVK50bL0Meta } from "/home/admin/www/pronomejo.net/release/20241027111005/pages/admin/profiles.vue?macro=true";
import { default as expensesAd7rbzXna5Meta } from "/home/admin/www/pronomejo.net/release/20241027111005/pages/admin/timesheets/expenses.vue?macro=true";
import { default as indexgYG00chXNwMeta } from "/home/admin/www/pronomejo.net/release/20241027111005/pages/admin/timesheets/index.vue?macro=true";
import { default as overviewGO8SfTor1AMeta } from "/home/admin/www/pronomejo.net/release/20241027111005/pages/admin/timesheets/overview.vue?macro=true";
import { default as awaitingDKMAhavlnyMeta } from "/home/admin/www/pronomejo.net/release/20241027111005/pages/admin/translations/awaiting.vue?macro=true";
import { default as missingdk7hyvX31nMeta } from "/home/admin/www/pronomejo.net/release/20241027111005/pages/admin/translations/missing.vue?macro=true";
import { default as usersY26otemQpUMeta } from "/home/admin/www/pronomejo.net/release/20241027111005/pages/admin/users.vue?macro=true";
import { default as apiNZbA1RS9s5Meta } from "/home/admin/www/pronomejo.net/release/20241027111005/pages/api.vue?macro=true";
import { default as _91slug_93gvOWcEHch3Meta } from "/home/admin/www/pronomejo.net/release/20241027111005/pages/blog/[slug].vue?macro=true";
import { default as indexNhBvnLaIuQMeta } from "/home/admin/www/pronomejo.net/release/20241027111005/pages/blog/index.vue?macro=true";
import { default as _91_91year_93_93P3TwLtVerPMeta } from "/home/admin/www/pronomejo.net/release/20241027111005/pages/calendar/[[year]].vue?macro=true";
import { default as _91year_93_45_91month_93_45_91day_93BGwOSZzIZUMeta } from "/home/admin/www/pronomejo.net/release/20241027111005/pages/calendar/[year]-[month]-[day].vue?macro=true";
import { default as adminrArbxMOqbLMeta } from "/home/admin/www/pronomejo.net/release/20241027111005/pages/census/admin.vue?macro=true";
import { default as indexahYJet4vSnMeta } from "/home/admin/www/pronomejo.net/release/20241027111005/pages/census/index.vue?macro=true";
import { default as contactdTR1y1MrYrMeta } from "/home/admin/www/pronomejo.net/release/20241027111005/pages/contact.vue?macro=true";
import { default as designAkE24KhYAMMeta } from "/home/admin/www/pronomejo.net/release/20241027111005/pages/design.vue?macro=true";
import { default as englishFA1rsbdbpYMeta } from "/home/admin/www/pronomejo.net/release/20241027111005/pages/english.vue?macro=true";
import { default as faqaXiqILU8TQMeta } from "/home/admin/www/pronomejo.net/release/20241027111005/pages/faq.vue?macro=true";
import { default as inclusivelchk3RYrJlMeta } from "/home/admin/www/pronomejo.net/release/20241027111005/pages/inclusive.vue?macro=true";
import { default as indexOyPERMQDbrMeta } from "/home/admin/www/pronomejo.net/release/20241027111005/pages/index.vue?macro=true";
import { default as licenseMYzcmJjJ4OMeta } from "/home/admin/www/pronomejo.net/release/20241027111005/pages/license.vue?macro=true";
import { default as academicD0D7zOeCKPMeta } from "/home/admin/www/pronomejo.net/release/20241027111005/pages/links/academic.vue?macro=true";
import { default as indexZnbY0k8vK0Meta } from "/home/admin/www/pronomejo.net/release/20241027111005/pages/links/index.vue?macro=true";
import { default as mediaL4KmAj9X0HMeta } from "/home/admin/www/pronomejo.net/release/20241027111005/pages/links/media.vue?macro=true";
import { default as translinguisticssCcm9FLjVLMeta } from "/home/admin/www/pronomejo.net/release/20241027111005/pages/links/translinguistics.vue?macro=true";
import { default as zinesD1ei7tLiUMeta } from "/home/admin/www/pronomejo.net/release/20241027111005/pages/links/zine.vue?macro=true";
import { default as names2RLIKFKsBVMeta } from "/home/admin/www/pronomejo.net/release/20241027111005/pages/names.vue?macro=true";
import { default as index1tdoLj1MG1Meta } from "/home/admin/www/pronomejo.net/release/20241027111005/pages/nouns/index.vue?macro=true";
import { default as templatesGtvrCM9Gj8Meta } from "/home/admin/www/pronomejo.net/release/20241027111005/pages/nouns/templates.vue?macro=true";
import { default as peoplegmvmsUVOQ2Meta } from "/home/admin/www/pronomejo.net/release/20241027111005/pages/people.vue?macro=true";
import { default as privacykfEXTOH7PIMeta } from "/home/admin/www/pronomejo.net/release/20241027111005/pages/privacy.vue?macro=true";
import { default as _91username_93e5e2yXFvdnMeta } from "/home/admin/www/pronomejo.net/release/20241027111005/pages/profile/[username].vue?macro=true";
import { default as card_45_91username_93r2ToBx7nUPMeta } from "/home/admin/www/pronomejo.net/release/20241027111005/pages/profile/card-[username].vue?macro=true";
import { default as editor6zvOmqJXbsMeta } from "/home/admin/www/pronomejo.net/release/20241027111005/pages/profile/editor.vue?macro=true";
import { default as anytbcpWsUCwsMeta } from "/home/admin/www/pronomejo.net/release/20241027111005/pages/pronouns/any.vue?macro=true";
import { default as askPqS40oQSl7Meta } from "/home/admin/www/pronomejo.net/release/20241027111005/pages/pronouns/ask.vue?macro=true";
import { default as avoidingOnd1iLdiiNMeta } from "/home/admin/www/pronomejo.net/release/20241027111005/pages/pronouns/avoiding.vue?macro=true";
import { default as index0Nz7JgHXZkMeta } from "/home/admin/www/pronomejo.net/release/20241027111005/pages/pronouns/index.vue?macro=true";
import { default as mirrorhdaR9RR6c3Meta } from "/home/admin/www/pronomejo.net/release/20241027111005/pages/pronouns/mirror.vue?macro=true";
import { default as pronounFheAE6J8TNMeta } from "/home/admin/www/pronomejo.net/release/20241027111005/pages/pronouns/pronoun.vue?macro=true";
import { default as sourcesLDIHY7hXV1Meta } from "/home/admin/www/pronomejo.net/release/20241027111005/pages/sources.vue?macro=true";
import { default as teamUw507zSgJ1Meta } from "/home/admin/www/pronomejo.net/release/20241027111005/pages/team.vue?macro=true";
import { default as terminologysFFn3wV7umMeta } from "/home/admin/www/pronomejo.net/release/20241027111005/pages/terminology.vue?macro=true";
import { default as termsRiQtmNTheeMeta } from "/home/admin/www/pronomejo.net/release/20241027111005/pages/terms.vue?macro=true";
import { default as userTzu8TqvYD8Meta } from "/home/admin/www/pronomejo.net/release/20241027111005/pages/user.vue?macro=true";
import { default as workshopsmzHAJSTQdjMeta } from "/home/admin/www/pronomejo.net/release/20241027111005/pages/workshops.vue?macro=true";
export default [
  {
    name: "admin-abuseReports",
    path: "/admin/abuse-reports",
    meta: abuseReportsdzqOCCQkMUMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241027111005/pages/admin/abuseReports.vue")
  },
  {
    name: "admin-audit-log-username-id",
    path: "/admin/audit-log/:username()/:id()",
    component: () => import("/home/admin/www/pronomejo.net/release/20241027111005/pages/admin/audit-log/[username]/[id].vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/admin/www/pronomejo.net/release/20241027111005/pages/admin/index.vue")
  },
  {
    name: "admin-moderation",
    path: "/admin/moderation",
    component: () => import("/home/admin/www/pronomejo.net/release/20241027111005/pages/admin/moderation.vue")
  },
  {
    name: "admin-pendingBans",
    path: "/admin/pending-bans",
    meta: pendingBansI7GUyfD2TZMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241027111005/pages/admin/pendingBans.vue")
  },
  {
    name: "admin-profiles",
    path: "/admin/profiles",
    component: () => import("/home/admin/www/pronomejo.net/release/20241027111005/pages/admin/profiles.vue")
  },
  {
    name: "admin-timesheets-expenses",
    path: "/admin/timesheets/expenses",
    component: () => import("/home/admin/www/pronomejo.net/release/20241027111005/pages/admin/timesheets/expenses.vue")
  },
  {
    name: "admin-timesheets",
    path: "/admin/timesheets",
    component: () => import("/home/admin/www/pronomejo.net/release/20241027111005/pages/admin/timesheets/index.vue")
  },
  {
    name: "admin-timesheets-overview",
    path: "/admin/timesheets/overview",
    component: () => import("/home/admin/www/pronomejo.net/release/20241027111005/pages/admin/timesheets/overview.vue")
  },
  {
    name: "admin-translations-awaiting",
    path: "/admin/translations/awaiting",
    component: () => import("/home/admin/www/pronomejo.net/release/20241027111005/pages/admin/translations/awaiting.vue")
  },
  {
    name: "admin-translations-missing",
    path: "/admin/translations/missing",
    component: () => import("/home/admin/www/pronomejo.net/release/20241027111005/pages/admin/translations/missing.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/home/admin/www/pronomejo.net/release/20241027111005/pages/admin/users.vue")
  },
  {
    name: "api",
    path: "/api",
    meta: apiNZbA1RS9s5Meta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241027111005/pages/api.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93gvOWcEHch3Meta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241027111005/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexNhBvnLaIuQMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241027111005/pages/blog/index.vue")
  },
  {
    name: "calendar",
    path: "/calendar/:year?",
    meta: _91_91year_93_93P3TwLtVerPMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241027111005/pages/calendar/[[year]].vue")
  },
  {
    name: "calendarDay",
    path: "/calendar/:year()-:month()-:day()",
    meta: _91year_93_45_91month_93_45_91day_93BGwOSZzIZUMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241027111005/pages/calendar/[year]-[month]-[day].vue")
  },
  {
    name: "census-admin",
    path: "/census/admin",
    meta: adminrArbxMOqbLMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241027111005/pages/census/admin.vue")
  },
  {
    name: "census",
    path: "/census",
    meta: indexahYJet4vSnMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241027111005/pages/census/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactdTR1y1MrYrMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241027111005/pages/contact.vue")
  },
  {
    name: "design",
    path: "/design",
    component: () => import("/home/admin/www/pronomejo.net/release/20241027111005/pages/design.vue")
  },
  {
    name: "english",
    path: "/english",
    meta: englishFA1rsbdbpYMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241027111005/pages/english.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqaXiqILU8TQMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241027111005/pages/faq.vue")
  },
  {
    name: "inclusive",
    path: "/inclusive",
    meta: inclusivelchk3RYrJlMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241027111005/pages/inclusive.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/admin/www/pronomejo.net/release/20241027111005/pages/index.vue")
  },
  {
    name: "license",
    path: "/license",
    component: () => import("/home/admin/www/pronomejo.net/release/20241027111005/pages/license.vue")
  },
  {
    name: "links-academic",
    path: "/links/academic",
    meta: academicD0D7zOeCKPMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241027111005/pages/links/academic.vue")
  },
  {
    name: "links",
    path: "/links",
    meta: indexZnbY0k8vK0Meta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241027111005/pages/links/index.vue")
  },
  {
    name: "links-media",
    path: "/links/media",
    meta: mediaL4KmAj9X0HMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241027111005/pages/links/media.vue")
  },
  {
    name: "links-translinguistics",
    path: "/links/translinguistics",
    meta: translinguisticssCcm9FLjVLMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241027111005/pages/links/translinguistics.vue")
  },
  {
    name: "links-zine",
    path: "/links/zine",
    meta: zinesD1ei7tLiUMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241027111005/pages/links/zine.vue")
  },
  {
    name: "names",
    path: "/names",
    meta: names2RLIKFKsBVMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241027111005/pages/names.vue")
  },
  {
    name: "nouns",
    path: "/nouns",
    meta: index1tdoLj1MG1Meta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241027111005/pages/nouns/index.vue")
  },
  {
    name: "nouns-templates",
    path: "/nouns/templates",
    meta: templatesGtvrCM9Gj8Meta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241027111005/pages/nouns/templates.vue")
  },
  {
    name: "people",
    path: "/people",
    meta: peoplegmvmsUVOQ2Meta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241027111005/pages/people.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacykfEXTOH7PIMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241027111005/pages/privacy.vue")
  },
  {
    name: "profile-username",
    path: "/profile/:username()",
    meta: _91username_93e5e2yXFvdnMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241027111005/pages/profile/[username].vue")
  },
  {
    name: "profile-card-username",
    path: "/profile/card-:username()",
    meta: card_45_91username_93r2ToBx7nUPMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241027111005/pages/profile/card-[username].vue")
  },
  {
    name: "profile-editor",
    path: "/profile/editor",
    meta: editor6zvOmqJXbsMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241027111005/pages/profile/editor.vue")
  },
  {
    name: "pronouns-any",
    path: "/pronouns/any",
    meta: anytbcpWsUCwsMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241027111005/pages/pronouns/any.vue")
  },
  {
    name: "pronouns-ask",
    path: "/pronouns/ask",
    meta: askPqS40oQSl7Meta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241027111005/pages/pronouns/ask.vue")
  },
  {
    name: "pronouns-avoiding",
    path: "/pronouns/avoiding",
    meta: avoidingOnd1iLdiiNMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241027111005/pages/pronouns/avoiding.vue")
  },
  {
    name: "pronouns",
    path: "/pronouns",
    meta: index0Nz7JgHXZkMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241027111005/pages/pronouns/index.vue")
  },
  {
    name: "pronouns-mirror",
    path: "/pronouns/mirror",
    meta: mirrorhdaR9RR6c3Meta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241027111005/pages/pronouns/mirror.vue")
  },
  {
    name: "all",
    path: "/:path(.*)",
    meta: pronounFheAE6J8TNMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241027111005/pages/pronouns/pronoun.vue")
  },
  {
    name: "sources",
    path: "/sources",
    meta: sourcesLDIHY7hXV1Meta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241027111005/pages/sources.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: teamUw507zSgJ1Meta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241027111005/pages/team.vue")
  },
  {
    name: "terminology",
    path: "/terminology",
    meta: terminologysFFn3wV7umMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241027111005/pages/terminology.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsRiQtmNTheeMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241027111005/pages/terms.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: userTzu8TqvYD8Meta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241027111005/pages/user.vue")
  },
  {
    name: "workshops",
    path: "/workshops",
    meta: workshopsmzHAJSTQdjMeta || {},
    component: () => import("/home/admin/www/pronomejo.net/release/20241027111005/pages/workshops.vue")
  }
]